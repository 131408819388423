import { Card, type ReadCardResponse, type WriteCardResponse } from '@nsftx/systems-sdk';
import deviceManagementService from '@/modules/device-management/services/deviceManagementService';
import { logService } from '@/common/services/logger';
import { errorParser } from '@/common/services/error-parser';
import BaseError from '@/common/errors/BaseError';
import type { DmCardData } from '../interfaces';
import type { OperatorCardDataInfo } from '../types';
import { CardNid } from '../enums';
import { useCardReaderStore } from '../store';
import { cardWriteEventPubSub, CARD_WRITE_EVENT_ID } from '../cardReaderEventsService';
import {
  parseCardData,
  enrichCardData,
  getCardData,
  isCardEmpty,
} from './dmCardDataService';

const LOG_PREFIX = '[dmCardReaderService]';
let cardModule: typeof Card.prototype;

const handleCardRead = (cardDataResponse: ReadCardResponse) => {
  const cardReaderStore = useCardReaderStore();
  const rawCardData = cardDataResponse.card_data;

  logService.info(`${LOG_PREFIX} Card read.`, {
    code: 'DM_CARD_MODULE_CARD_READ',
    cardDataResponse,
  });

  if (!rawCardData) {
    cardReaderStore.onCardRead(undefined);
    return;
  }

  const parsedCardData = parseCardData(rawCardData);
  if (!parsedCardData) {
    cardReaderStore.onCardRead(undefined);
    return;
  }

  const cardData = getCardData(parsedCardData);
  cardReaderStore.onCardRead(cardData);
  cardData.cardEmpty = isCardEmpty();
};

const handleCardOut = () => {
  const cardReaderStore = useCardReaderStore();

  logService.info(`${LOG_PREFIX} Card out.`, {
    code: 'DM_CARD_MODULE_CARD_OUT',
    cardData: cardReaderStore.cardData,
  });
  cardReaderStore.onCardOut();
};

const handleCardWrite = (response: WriteCardResponse) => {
  logService.info(`${LOG_PREFIX} Card write response.`, {
    code: 'DM_CARD_MODULE_CARD_WRITE_RESPONSE',
    response,
  });

  cardWriteEventPubSub.publish(CARD_WRITE_EVENT_ID, response);
};

const init = (): void => {
  deviceManagementService
    .getCore()
    .then((core) => {
      logService.info(`${LOG_PREFIX} Initializing DM card module`, {
        code: 'DM_CARD_MODULE_INITIALIZING',
      });
      cardModule = new Card(core);

      cardModule.on('ready', () => {
        logService.info(`${LOG_PREFIX} Card module successfully initialized.`, {
          code: 'DM_CARD_MODULE_INIT_SUCCESS',
        });

        cardModule.on('cardRead', handleCardRead);
        cardModule.on('cardOut', handleCardOut);
        cardModule.on('cardWrite', handleCardWrite);
      });
    })
    .catch((error) => {
      logService.error(
        `${LOG_PREFIX} Card module cannot be initialized since core is not installed.`,
        {
          code: 'DM_CARD_MODULE_INIT_FAILED',
          ...errorParser.parseUpstream(error),
        },
      );
    });
};

const writeToCard = (cardData: DmCardData) => new Promise<WriteCardResponse>(
  (resolve, reject) => {
    const enrichedCardData = enrichCardData(cardData);
    logService.info(`${LOG_PREFIX} Writing data to card`, {
      code: 'DM_CARD_MODULE_WRITE_TO_CARD',
      enrichedCardData,
      cardData,
    });
    cardModule.writeCard(enrichedCardData);

    const cardWriteSubscribeId = cardWriteEventPubSub
      .subscribe(CARD_WRITE_EVENT_ID, (event, response) => {
        cardWriteEventPubSub.unsubscribe(cardWriteSubscribeId);
        if (response?.card_action_status?.success) {
          resolve(response);
        } else {
          logService.error(`${LOG_PREFIX} Writing data to card failed`, {
            code: 'DM_CARD_MODULE_WRITE_TO_CARD_ERROR',
            enrichedCardData,
            response,
          });
          reject(response);
        }
      });
  },
);

const readCardData = () => {
  const cardReaderStore = useCardReaderStore();

  if (!cardReaderStore.isCardIn()) {
    return Promise.reject(new BaseError('Card is out', 'DM_READ_CARD_DATA_CARD_OUT'));
  }

  if (cardReaderStore.cardData) {
    return Promise.resolve(cardReaderStore.cardData);
  }

  return Promise.resolve({ cardEmpty: true, u: '', h: '' });
};

const writeToOperatorCard = (cardInfo: OperatorCardDataInfo) => writeToCard({
  u: cardInfo.userUuid,
  h: cardInfo.password,
  n: CardNid.hdioa762,
  p: cardInfo.pin ? '1' : '0',
});

const writeToPassCard = () => writeToCard({
  u: '000000000000000000000000000000000000',
  h: '000000000000000000000000000000000000',
  n: CardNid.passCard,
});

const formatCard = () => writeToCard({
  u: '000000000000000000000000000000000000',
  h: '000000000000000000000000000000000000',
  n: CardNid.formatted,
  p: '0',
});

export default {
  init,
  readCardData,
  writeToOperatorCard,
  writeToPassCard,
  formatCard,
  isCardEmpty,
};
